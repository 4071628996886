import { useSelector } from 'react-redux';
import { useDisplayError } from 'modules/errors';
import { useElevatorCoordinates } from 'modules/entities/modules/elevator-coordinates';
import { selectWorkordersCount, selectSortedWorkorders } from '../services/selectors';
import { useFetchWorkorders } from './useFetchWorkorders';
import { DEFAULT_SORT } from '../constants';
import { useOrderSearchParams, useSearchSearchParams } from 'modules/table';

export const useWorkorders = () => {
    const api = useFetchWorkorders();
    const { search, defaultValue } = useSearchSearchParams();
    const sorting = useOrderSearchParams() ?? DEFAULT_SORT;

    const workorders = useSelector(state =>
        selectSortedWorkorders(state, {
            sorting,
            search
        }),
    );
    const workordersCount = useSelector(selectWorkordersCount);
    const { progress: coordinatesProgress } = useElevatorCoordinates();
    useDisplayError(api.error);
    return {
        loading: api.inProgress || coordinatesProgress !== 100,
        workorders,
        empty: workordersCount === 0,
        defaultSearch: defaultValue
    };
};
