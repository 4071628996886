import { WorkorderField } from 'modules/entities/modules/workorders';
import { Input } from 'modules/form';
import { TableSearch, useTableSearch } from 'modules/table';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const WorkOrdersSearchPanel = ({ defaultSearch }) => {
  const { value, handleValue, handleSubmit, handleReset } = useTableSearch(defaultSearch);

  const intl = useIntl();
  return (
    <TableSearch
      fields={[
        {
          label: <FormattedMessage id={`work.orders.table.name`} />,
          labelFor: WorkorderField.NAME,
          renderer: (
            <Input
              id={WorkorderField.NAME}
              data-testid={WorkorderField.NAME}
              value={value[WorkorderField.NAME]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  Name: target.value,
                });
              }}
            />
          ),
        },
        {
          label: <FormattedMessage id={`work.orders.table.status`} />,
          labelFor: WorkorderField.STATUS,
          renderer: (
            <Input
              id={WorkorderField.STATUS}
              data-testid={WorkorderField.STATUS}
              value={value[WorkorderField.STATUS]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  Status__c: target.value,
                });
              }}
            />
          ),
        },
        {
          label: <FormattedMessage id={`work.orders.table.city`} />,
          labelFor: WorkorderField.CITY,
          renderer: (
            <Input
              id={WorkorderField.CITY}
              data-testid={WorkorderField.CITY}
              value={value[WorkorderField.CITY]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  City__c: target.value,
                });
              }}
            />
          ),
        },
        {
          label: <FormattedMessage id={`work.orders.table.street`} />,
          labelFor: WorkorderField.STREET,
          renderer: (
            <Input
              id={WorkorderField.STREET}
              data-testid={WorkorderField.STREET}
              value={value[WorkorderField.STREET]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  Street__c: target.value,
                });
              }}
            />
          ),
        },
        {
          label: <FormattedMessage id={`work.orders.table.zipcode`} />,
          labelFor: WorkorderField.ZIP_CODE,
          renderer: (
            <Input
              id={WorkorderField.ZIP_CODE}
              data-testid={WorkorderField.ZIP_CODE}
              value={value[WorkorderField.ZIP_CODE]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  Street__c: target.value,
                });
              }}
            />
          ),
        },
        {
          label: <FormattedMessage id={`work.orders.table.portfolio`} />,
          labelFor: WorkorderField.PORTFOLIO,
          renderer: (
            <Input
              id={WorkorderField.PORTFOLIO}
              data-testid={WorkorderField.PORTFOLIO}
              value={value[WorkorderField.PORTFOLIO]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  Portfolio: target.value,
                });
              }}
            />
          ),
        },
        {
          label: <FormattedMessage id={`work.orders.table.assistantName`} />,
          labelFor: WorkorderField.ASSISTANT_NAME,
          renderer: (
            <Input
              id={WorkorderField.ASSISTANT_NAME}
              data-testid={WorkorderField.ASSISTANT_NAME}
              value={value[WorkorderField.ASSISTANT_NAME]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  AssistantName: target.value,
                });
              }}
            />
          ),
        },
        {
          label: <FormattedMessage id={`work.orders.table.technicianName`} />,
          labelFor: WorkorderField.TECHNICIAN_NAME,
          renderer: (
            <Input
              id={WorkorderField.TECHNICIAN_NAME}
              data-testid={WorkorderField.TECHNICIAN_NAME}
              value={value[WorkorderField.TECHNICIAN_NAME]}
              placeholder={intl.formatMessage({ id: 'table.search.placeholder' })}
              onChange={({ target }) => {
                handleValue({
                  TechnicanName: target.value,
                });
              }}
            />
          ),
        },
      ]}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      rowGutter={[16, 16]}
      colSizes={{ sm: 24, md: 12, lg: 8, xxl: 5 }}
    />
  );
};

export default WorkOrdersSearchPanel;
