import { useWorkorders } from 'modules/entities/modules/workorders/hooks/useWorkorders';
import { Table, TableActionPanel, TableSort, useUrlPagination } from 'modules/table';
import { SortingType } from 'constants/index';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import WorkOrdersSearchPanel from '../WorkOrdersSearchPanel/WorkOrdersSearchPanel';
import { WorkOrderState, WorkorderField } from 'modules/entities/modules/workorders';
import { Tag } from 'antd';
import config from 'config';

const SORT_FIELDS = [
  {
    field: WorkorderField.NAME,
    labelId: 'work.orders.table.name',
  },
  {
    field: WorkorderField.STATUS,
    labelId: 'work.orders.table.status',
  },
  {
    field: WorkorderField.CITY,
    labelId: 'work.orders.table.city',
  },
  {
    field: WorkorderField.STREET,
    labelId: 'work.orders.table.street',
  },
  {
    field: WorkorderField.ZIP_CODE,
    labelId: 'work.orders.table.zipcode',
  },
  {
    field: WorkorderField.PORTFOLIO,
    labelId: 'work.orders.table.portfolio',
  },
  {
    field: WorkorderField.ASSISTANT_NAME,
    labelId: 'work.orders.table.assistantName',
  },
  {
    field: WorkorderField.TECHNICIAN_NAME,
    labelId: 'work.orders.table.technicianName',
  },
];

const DEFAULT_SORT = {
  type: SortingType.ASC,
  field: WorkorderField.NAME,
};

const WorkOrdersTable = () => {
  const { workorders, loading, defaultSearch } = useWorkorders();

  const pagination = useUrlPagination();

  const getColor = (status: WorkOrderState): string => {
    switch (status) {
      case WorkOrderState.NEW:
        return 'green';
      case WorkOrderState.IN_CLARIFICATION:
        return 'orange';
      case WorkOrderState.NOT_RELEVANT:
        return 'gray';
      case WorkOrderState.RUNNING:
        return 'blue';
      case WorkOrderState.WAITING_FOR_ASSISTANT:
        return 'purple';
      case WorkOrderState.WAITING_FOR_TECHNICIAN:
        return 'yellow';
      case WorkOrderState.TECHNICIAN_REVIEW_REQUIRED:
        return 'red';
      case WorkOrderState.FINISHED:
        return 'darkgreen';
      case WorkOrderState.REJECTED:
        return 'darkred';
      case WorkOrderState.TECHNICAL_Q_CHECK_DONE:
        return 'lightblue';
      case WorkOrderState.IN_REVIEW:
        return 'teal';
      case WorkOrderState.FULFILLED:
        return 'gold';
      case WorkOrderState.UNKNOWN:
        return 'black';
      default:
        break;
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="work.orders.table.name" />,
      dataIndex: WorkorderField.NAME,
      key: WorkorderField.NAME,
    },
    {
      title: <FormattedMessage id="work.orders.table.status" />,
      dataIndex: WorkorderField.STATUS,
      render: (_, record) => <Tag color={getColor(record[WorkorderField.STATUS])}>{record[WorkorderField.STATUS]}</Tag>,
    },
    {
      title: <FormattedMessage id="work.orders.table.city" />,
      dataIndex: WorkorderField.CITY,
      key: WorkorderField.CITY,
    },
    {
      title: <FormattedMessage id="work.orders.table.street" />,
      dataIndex: WorkorderField.STREET,
      key: WorkorderField.STREET,
    },
    {
      title: <FormattedMessage id="work.orders.table.zipcode" />,
      dataIndex: WorkorderField.ZIP_CODE,
      key: WorkorderField.ZIP_CODE,
    },
    {
      title: <FormattedMessage id="work.orders.table.portfolio" />,
      dataIndex: WorkorderField.PORTFOLIO,
      key: WorkorderField.PORTFOLIO,
    },
    {
      title: <FormattedMessage id="work.orders.table.assistantName" />,
      dataIndex: WorkorderField.ASSISTANT_NAME,
      key: WorkorderField.ASSISTANT_NAME,
    },
    {
      title: <FormattedMessage id="work.orders.table.technicianName" />,
      dataIndex: WorkorderField.TECHNICIAN_NAME,
      key: WorkorderField.TECHNICIAN_NAME,
    },
  ];
  return (
    <div
      style={{
        padding: '40px 0',
      }}
    >
      <div
        style={{
          padding: '20px 0',
        }}
      >
        <WorkOrdersSearchPanel defaultSearch={defaultSearch} />
      </div>
      <TableActionPanel>
        <TableSort fields={SORT_FIELDS} defaultField={DEFAULT_SORT.field} defaultType={DEFAULT_SORT.type} />
      </TableActionPanel>
      <Table
        columns={columns}
        dataSource={workorders}
        hideSort={false}
        toolBarRender={false}
        search={false}
        pagination={pagination}
        loading={loading}
        rowKey="Id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              const url = `${config.salesforce}/${record.Id}`;
              window.open(url, '_blank', 'noopener,noreferrer');
            }, // click row
          };
        }}
      />
    </div>
  );
};

export default WorkOrdersTable;
