import { TableActionPanel, TableSort } from 'modules/table';
import React from 'react';
import { Table, useUrlPagination } from 'modules/table';
import { Image, Dropdown, Menu, Button, Tag } from 'antd';
import more from 'assets/images/more.svg';
import * as felaRules from './TeamsTable.styles';
import { useFelaEnhanced } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { TeamDialogState } from 'modules/teams/context/TeamDialogContext';
import { useTeamDialog } from 'modules/teams/hooks/useTeamDialog';
import { SORT_FIELDS, DEFAULT_SORT } from 'modules/entities/modules/teams/constants';
import { useSetUser } from 'modules/entities/modules/teams/hooks/useSetUser';

const menuOptions = user => [
  {
    label: <FormattedMessage id="teams.table.changeRole" />,
    id: TeamDialogState.CHANGE_ROLE,
  },
  {
    label: <FormattedMessage id="teams.table.editNotifications" />,
    id: TeamDialogState.NOTIFICATIONS,
  },
  {
    label: user?.isActive ? (
      <FormattedMessage id="teams.table.deactivateUser" />
    ) : (
      <FormattedMessage id="teams.table.activateUser" />
    ),
    id: TeamDialogState.DEACTIVATE_USER,
  },
];

const TeamsTable = ({ users, loading }) => {
  const { styles } = useFelaEnhanced(felaRules);
  const { setState } = useTeamDialog();
  const pagination = useUrlPagination();
  const { handleSetUser } = useSetUser();

  const getRole = record => {
    const role = (record?.role || '')?.split(' ')?.join('.')?.toLowerCase();
    if(role) return <FormattedMessage id={(record.role || '').split(' ').join('.').toLowerCase()} />
    return ''
  }

  const columns = [
    {
      title: <FormattedMessage id="teams.table.name" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <FormattedMessage id="teams.table.email" />,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <FormattedMessage id="teams.table.role" />,
      dataIndex: 'role',
      render: (_, record) => getRole(record),
    },
    {
      title: <FormattedMessage id="teams.table.active" />,
      dataIndex: 'isActive',
      render: (_, record) => (
        <Tag color={record.isActive ? 'green' : 'red'}>{record.isActive ? 'Active' : 'Inactive'}</Tag>
      ),
    },
    {
      title: <FormattedMessage id="teams.table.elevators" />,
      dataIndex: 'totalElevators',
      key: 'totalElevators',
    },
    {
      title: '',
      key: 'option',
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              {menuOptions(record).map(option => (
                <Menu.Item
                  key={option.id}
                  onClick={() => {
                    handleSetUser(record);
                    setState(option.id);
                  }}
                >
                  {option.label}
                </Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button onClick={e => e.preventDefault()} className={styles.moreButton}>
            <Image src={more} alt="more" preview={false} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <TableActionPanel>
        <TableSort fields={SORT_FIELDS} defaultField={DEFAULT_SORT.field} defaultType={DEFAULT_SORT.type} />
      </TableActionPanel>
      <Table
        columns={columns}
        dataSource={users}
        hideSort={false}
        toolBarRender={false}
        search={false}
        pagination={pagination}
        loading={loading}
        rowKey="userId"
      />
    </div>
  );
};

export default TeamsTable;
